<template>
    <div class="form-row">
    	<div class="col-12">
    		<div class="card">
    			<div class="card-header">
    				<div class="row justify-content-between align-items-center">
    					<div class="col-md-auto">
    						<h5 class="card-title font-weight-semibold">Jumlah Pasien UGD</h5>
    					</div>
    					<div class="col-md-5">
    						<div class="form-row justify-content-end">
    							<div class="col-md-8 patientPicker" v-if="filter.periode == 'H'" style="padding-left: 40px;">
    								<div class="form-group mb-0 d-flex">
    									<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
    										:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
    										:ranges="datePickerConfig.ranges" @update="updateValues">
    										<template v-slot:input="picker">
    											{{ picker.startDate | date }} - {{ picker.endDate | date }}
    										</template>
    									</date-range-picker>
    									<div class="input-group-append calendar-group">
    										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
    									</div>
    								</div>
    							</div>
    							<div class="col-md-6 patientPicker" v-else>
    								<v-select placeholder="Pilih Tahun" @input="doFill" v-model="filter.tahun" :options="mTahun"
    									label="text" :reduce="v=>v.value"></v-select>
    							</div>
    							<div class="col-md-6 col-lg-4">
    								<v-select placeholder="Pilih Periode" @input="doFill" v-model="filter.periode" :options="mPeriode"
    									label="text" :reduce="v=>v.value"></v-select>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    			<div class="card-body">
    				<div class="chart-container">
    					<BarChart :chartData="resumeDataPasien" :chartOptions="chartOptions" />
    				</div>
    			</div>
    		</div>
    	</div>
    	<div class="col-12">
    		<div class="my-3">
    			<div class="row align-items-center">
    				<div class="col-md-auto">
    					<h5 class="font-weight-semibold mb-0">Informasi Pasien UGD</h5>
    				</div>
    				<div class="col-md-auto">
    					<div class="form-group mb-0 d-flex">
    						<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
    							:autoApply="datePickerConfig.autoApply" v-model="dateRange2" :opens="'right'"
    							:ranges="datePickerConfig.ranges" @update="updateValues">
    							<template v-slot:input="picker">
    								{{ picker.startDate | date }} - {{ picker.endDate | date }}
    							</template>
    						</date-range-picker>
    						<div class="input-group-append calendar-group">
    							<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    	<div class="col-12">
    		<div class="mb-3">
    			<div class="row no-gutters">
    				<div class="col border-top border-bottom border-left">
    					<div class="ugd-item bg-white">
    						<div class="uit-count"><span class="font-weight-bold text-warning-600">
    								{{resumeInformasi.riu_belum_triase_total||0}}</span></div>
    						<div class="uit-label text-warning-600">Belum Triase</div>
    					</div>
    				</div>
    				<div class="col border-top border-bottom">
    					<div class="ugd-item triase_red">
    						<div class="uit-count"><span class="font-weight-bold text-danger-600">
    								{{resumeInformasi.riu_esi_level_12||0}}</span></div>
    						<div class="uit-label text-white">ESI Level 1 - 2</div>
    					</div>
    				</div>
    				<div class="col border-top border-bottom">
    					<div class="ugd-item triase_yellow">
    						<div class="uit-count"><span class="font-weight-bold text-warning">
    								{{resumeInformasi.riu_esi_level_34||0}}</span></div>
    						<div class="uit-label text-white">ESI Level 3 - 4</div>
    					</div>
    				</div>
    				<div class="col border-top border-bottom">
    					<div class="ugd-item triase_green">
    						<div class="uit-count"><span class="font-weight-bold text-success">
    								{{resumeInformasi.riu_esi_level_5||0}}</span></div>
    						<div class="uit-label text-white">ESI Level 5</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    	<div class="col-md-6">
    		<div class="card mb-2">
    			<div class="card-header">
    				<h5 class="card-title font-weight-semibold">Detail Asal Rujukan Pasien</h5>
    			</div>
    			<div class="wrap_scroll">
    				<div class="table-responsive">
    					<table class="table table-bordered table-striped">
    						<thead>
    							<tr>
    								<th>Asal Rujukan</th>
    								<th>Jumlah</th>
    							</tr>
    						</thead>
    						<tbody>
    							<tr>
    								<td>Datang Sendiri</td>
    								<td>{{resumeInformasi.riu_datang_sendiri||0}}</td>
    							</tr>
    							<tr>
    								<td>Rujukan Dokter</td>
    								<td>{{resumeInformasi.riu_rujukan_dokter||0}}</td>
    							</tr>
    							<tr>
    								<td>Rujukan Rumah Sakit</td>
    								<td>{{resumeInformasi.riu_rujukan_rs||0}}</td>
    							</tr>
    							<tr>
    								<td>Rujukan Puskesmas</td>
    								<td>{{resumeInformasi.riu_rujukan_puskesmas||0}}</td>
    							</tr>
    						</tbody>
    					</table>
    				</div>
    			</div>
    		</div>
    	</div>
    	<div class="col-md-6">
    		<div class="card mb-2s">
    			<div class="card-header">
    				<h5 class="card-title font-weight-semibold">Detail Cara Keluar Pasien </h5>
    			</div>
    			<div class="wrap_scroll">
    				<div class="table-responsive">
    					<table class="table  table-bordered table-striped">
    						<thead>
    							<tr>
    								<th>Cara Keluar</th>
    								<th>Jumlah</th>
    							</tr>
    						</thead>
    						<tbody>
    							<tr>
    								<td>Rujuk</td>
    								<td>{{resumeInformasi.riu_ck_rujuk||0}}</td>
    							</tr>
    							<tr>
    								<td>Rawat Inap</td>
    								<td>{{resumeInformasi.riu_ck_ranap||0}}</td>
    							</tr>
    							<tr>
    								<td>Rawat Jalan</td>
    								<td>{{resumeInformasi.riu_ck_rajal||0}}</td>
    							</tr>

    							<tr>
    								<td>Observasi</td>
    								<td>{{resumeInformasi.riu_ck_observasi||0}}</td>
    							</tr>
    							<tr>
    								<td>Melahirkan</td>
    								<td>{{resumeInformasi.riu_ck_partus||0}}</td>
    							</tr>
    							<tr>
    								<td>Keluhan Membaik</td>
    								<td>{{resumeInformasi.riu_ck_keluhan_membaik||0}}</td>
    							</tr>
    							<tr>
    								<td>Meninggal di UGD</td>
    								<td>{{resumeInformasi.riu_ck_meninggal_ugd||0}}</td>
    							</tr>
    							<tr>
    								<td>DOA/Meninggal</td>
    								<td>{{resumeInformasi.riu_ck_doa||0}}</td>
    							</tr>
    							<tr>
    								<td>Menolak Rawat Inap</td>
    								<td>{{resumeInformasi.riu_ck_menolak_ranap||0}}</td>
    							</tr>
    						</tbody>
    					</table>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import Gen from '@/libs/Gen.js'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    import _ from 'lodash'
    import $ from 'jquery'
    import BarChart from '@/components/chart/BarChart'


        
    export default {
        extends: GlobalVue,
        data() {
            return {
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                dateRange2: {
                    startDate: new Date(),
                    endDate: new Date(),
                },

                resumeDataPasien: {
                    "labels": ["Jumlah Pasien UGD","Test"],
                    "datasets": [
                        {
                            "label": "Data Antrean loket 7 hari terakhir",
                            "data": [200,100],
                            "backgroundColor": ["#777","#888"]
                        }
                    ]
                },
                resumeInformasi: {},
                chartOptions: {
                    responsive: true
                },
								modulePage: "Dashboard",
    						loadingReport: false,
                mTahun: [],
                mPeriode: [
                    {
                        text: "Harian",
                        value: "H",
                    },
                    {
                        text: "Bulanan",
                        value: "B",
                    },
                ]
            }
        },
        
        components:{
            DateRangePicker,BarChart
        },

        
        methods: {
            doFill(){
                this.doFilter()
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
                
                this.filter.startDate2 = moment(this.dateRange2.startDate).format('YYYY-MM-DD')
                this.filter.endDate2 = moment(this.dateRange2.endDate).format('YYYY-MM-DD')
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            apiGet(isLoad = true){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                this.doConvertDate()
                // this.loadingOverlay = true
                
                this.loadingOverlay = true
            
                Gen.apiRest(
                    "/get/"+this.modulePage+
                    '/admisiUGD', 
                    {
                        params: Object.assign({
                            startDate : this.filter.startDate,
                            endDate : this.filter.endDate,
                            startDate2 : this.filter.startDate2,
                            endDate2 : this.filter.endDate2,
                            periode: this.filter.periode ? this.filter.periode : 'B',
                            tahun: this.filter.tahun ? this.filter.tahun : moment().format("YYYY"),
                        }, paramsQuery ||{})
                    }
                ).then(res=>{
                    let resp = res.data
                    this.mTahun = resp.mTahun
                    this.resumeInformasi = resp.resumeInformasi
                    console.log(resp.resumeDataPasien)
                    console.log({
                        "labels": ["Jumlah Pasien UGD","Test"],
                        "datasets": [
                            {
                                "label": "Data Antrean loket 7 hari terakhir",
                                "data": [200,100],
                                "backgroundColor": ["#777","#888"]
                            }
                        ]
                    })
                    this.resumeDataPasien = resp.resumeDataPasien
                    this.loadingOverlay = false

                    // this.loadingOverlay = false
                })
            }
        },
        
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        mounted() {
            
            if(this.$route.query.startDate && this.$route.query.endDate){
                this.dateRange.startDate = this.$route.query.startDate
                this.dateRange.endDate = this.$route.query.endDate
            }
            
            this.apiGet(false) 
            this.filter.tahun = moment().format("YYYY")
            this.filter.periode = 'B'

        },
        watch:{
            $route(){
                this.apiGet()
            }
        }
    }
</script>