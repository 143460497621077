<template>
  <div class="content-wrapper">
    <div class="page-header">
      <div class="page-header-content header-elements-md-inline">
        <div class="page-title d-flex">
          <h4>
            <span class="font-weight-semibold">Dashboard</span>
          </h4>
          <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>
        <div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">
        </div>
      </div>
    </div>
    <div class="content pt-0">
      <DashboardAdmisiUGD /> 
      
      <!--
      <DashboardAdmisiUGD v-if="isAdmisi || isAdmisiUGD || isPerawatUGD || isDokterUGD || isDokterRanap || isBidan || (selectedDashboard == uAdmisiUGD || selectedDashboard == uAdmisi)"/> 
      -->


	  </div>
  </div>
</template>
<script>

import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import _ from 'lodash'
import DashboardAdmisiUGD from '@/views/backend/Dashboard/DashboardAdmisiUGD.vue'


export default {
 	extends: GlobalVue,
  components:{DashboardAdmisiUGD}
}

</script>